import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useLocalJsonForm } from "gatsby-tinacms-json"

import SEO from "../components/seo"
import { PhoneCarousel } from "../blocks/PhoneCarousel"
import { PlainSelector } from "../blocks/PlainSelector"
import { SolidBoi } from "../blocks/SolidBoi"
import { PageForm, Page } from "../templates/page"

const AppStore = require("../images/download-maxrewards-app-store.svg")
const PlayStore = require("../images/download-maxrewards-play-store.svg")

const LocalDealsPage = ({ data }) => {
  const [page]: any = useLocalJsonForm(data?.dataJson, PageForm)
  const { works, benefits } = page
  // const works = page?.works ? page?.works : [];
  // const benefits = page?.benefits ? page?.benefits : [];

  return (
    <Page
      title={page?.title || ""}
      description={page?.description || ""}
      useCustomHeader
    >
      <section
        className="uk-section uk-section-primary gradient"
        data-uk-scrollspy="target: h1, h2, p, .uk-position-relative, .height-xsmall, .uk-card; cls: uk-animation-slide-bottom-medium; delay: 100"
      >
        <div className="uk-container uk-text-center local-deal-header">
          <div className="uk-alert-danger uk-text-center uk-padding-small">
            Local Deals has been decommissioned for now.
          </div>
          <div className="uk-child-width-1-1 uk-child-width-1-2@m" data-uk-grid>
            <div className="uk-text-left@m uk-margin-large-top">
              <h1 className="uk-text-left@m">
                Support your community and earn extra cashback with{" "}
                <span className="accent">Local Deals</span>
              </h1>

              <div
                className="uk-position-relative uk-flex-center uk-flex-left@m uk-grid-small"
                data-uk-grid
              >
                <div>
                  <a href="https://apps.apple.com/us/app/maxrewards-earn-more-rewards/id1435710443">
                    {/* <img alt='Download on the App Store' className="height-xsmall animate" src="download-maxrewards-app-store.svg" /> */}
                    <AppStore className="height-xsmall" />
                  </a>
                </div>

                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.maxrewards&hl=en_US&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    {/* <img alt='Get it on Google Play' className="height-xsmall animate" src="download-maxrewards-play-store.svg" /> */}
                    <PlayStore className="height-xsmall" />
                  </a>
                </div>
              </div>
            </div>

            <div className="uk-position-relative uk-flex-first@m">
              <img
                className="local-deal-hero"
                src="/local-deals.png"
                alt="Local Deals"
              />
            </div>
          </div>
        </div>

        <div className="stripe"></div>
        <div className="uk-container uk-position-relative uk-margin-large-top">
          <div
            className="uk-grid-match uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center"
            data-uk-grid
          >
            <div className="uk-width-1-1">
              <h2 className="uk-text-center">How It Works</h2>
            </div>
            {works &&
              works.map((work) => (
                <div>
                  <div className="uk-card uk-card-default uk-card-body">
                    <div className="uk-margin-auto uk-width-3-4">
                      <img src={work?.img || ""} />
                    </div>

                    <h3 className="title">{work?.title}</h3>

                    <h5>{work?.text}</h5>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      <section
        className="uk-section uk-section-muted uk-padding-remove-top"
        data-uk-scrollspy="target: h2, img, h3, h5; cls: uk-animation-slide-bottom-medium; delay: 100"
      >
        <div className="uk-container uk-text-center">
          <h2 className="uk-text-center uk-margin-large-bottom">
            The most frictionless way to save money
          </h2>
          <div
            id="local-deal-benefits"
            className="uk-grid-divider uk-grid-large uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center"
            data-uk-grid
          >
            {benefits &&
              benefits.map((benefit) => (
                <div>
                  <img
                    className="uk-width-small uk-width-1-2@s"
                    src={benefit?.img || ""}
                    alt="{ benefit?.title }"
                  />
                  <h3 className="title">{benefit?.title}</h3>
                  <h5>{benefit?.text}</h5>
                </div>
              ))}
          </div>
        </div>
      </section>
    </Page>
  )
}

export const query = graphql`
  query localDealsQuery {
    dataJson(fileRelativePath: { eq: "/src/data/local-deals.json" }) {
      title
      description
      works {
        img
        title
        text
      }
      benefits {
        img
        title
        text
      }
      blocks {
        _template
        title
        linkTitle
        linkUrl
        id
        data {
          title
          description
          iconColor
          img
          text
          image
        }
        reverse
        title
        use_dark
        shapes
        use_different_header
        other_section_classes {
          remove_padding_top
          remove_padding_bot
          extra_padding_bot
        }
        testimonial {
          title
          text
          bg_decoration
        }
        use_test
        use_custom_footer
      }

      # TinaCMS fields
      rawJson
      fileRelativePath
    }
  }
`

export default LocalDealsPage
